<template>
  <div class="benefits-list">
    <div class="benefits-list__title" v-if="title">
      <span class="benefits-list__font benefits-list__font--title">
        {{ title }}
      </span>
    </div>

    <ul class="benefits-list__list">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="benefits-list__item"
        :class="{
          [`benefits-list__item--marker--${marker}`]: marker,
        }"
      >
        <span class="benefits-list__item-content">
          <span
            class="benefits-list__font benefits-list__font--text"
            v-html="item.text"
          />
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BenefitsList",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    marker: {
      type: String,
      default: "default",
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.benefits-list {
  $parent: &;

  &__title {
    height: auto;
  }

  &__list {
    margin-top: em(7);
    padding-left: 0;
  }

  &__item {
    display: flex;
    position: relative;
    padding: em(6) em(22) em(5) em(29);

    &:not(:last-child) {
      margin-bottom: em(10);
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: em(21);
      display: inline-block;
      height: em(25);
    }

    &--marker {
      &--default {
        &:before {
          content: url("/svg/fanflow.svg");
        }
      }
    }
  }

  &__font {
    display: block;
    white-space: pre-line;
    font-style: normal;
    font-family: $font-default;
    line-height: em(20);

    &--title {
      color: $black;
      font-size: em(18);
      font-weight: 500;
    }

    &--text {
      color: $app-font-color-gray-3;
      font-size: em(14);
      font-weight: 400;
    }
  }
}
</style>
